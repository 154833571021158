import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Link from '@uz/unitz-components-web/Link';
import Button from '@uz/unitz-components-web/Button';
import { Image } from '@uz/unitz-components-web/Image';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseTemplateAttachments from '@uz/unitz-tool-components/CourseTemplateAttachments';
import CourseTemplateLinkUrls from '@uz/unitz-tool-components/CourseTemplateLinkUrls';
import CourseTemplateLessonDescription from '@uz/unitz-tool-components/CourseTemplateLessonDescription';

export const View8 = () => {
  return (
    <DIV className="component" forceCtx>
      <div>
        <Row gutter={{ xs: 24, md: 18, lg: 16 }}>
          <Col span={4}>
            <div className="bg-background2 rounded-xl mb-4">
              <ResponsiveProps xs={{ height: 150 }} md={{ height: 152 }} lg={{ height: 152 }}>
                {(resProps) => (
                  <Image
                    style={{ objectFit: 'cover' }}
                    alt={`avt_${ctx.get('lesson_item.id')}`}
                    className="object-fill overflow-hidden rounded-xl"
                    src={CourseFormatter.avatarUrl(ctx)(ctx.get('lesson_item'))}
                    {...resProps}
                  />
                )}
              </ResponsiveProps>
            </div>
            <Link
              to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateDetail', {
                id: _.get(ctx.get('lesson_item'), 'id', ''),
              })}
            >
              <Button size="medium" block type="primary">
                {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Detail.course_detail')}
              </Button>
            </Link>
          </Col>
          <Col span={20}>
            <div className="flex flex-col space-y-1">
              <h3 className="text-xl font-semibold text-main">{ctx.get('lesson_item.name') || ' '}</h3>
              <CourseTemplateLessonDescription data={ctx.get('lesson_item.description')} />
              <CourseTemplateAttachments data={ctx.get('lesson_item')} />
              {!!ctx.get('lesson_item.link_urls.length') && <CourseTemplateLinkUrls data={ctx.get('lesson_item')} />}
            </div>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(View8);
