import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import ProgramTemplateLessonItemX from '@uz/unitz-tool-components/ProgramTemplateLessonItemX';
import { Row, Col } from 'antd';

export const View8 = () => {
  return (
    <DIV className="component">
      {!!ctx.get('@item.program_template_courses.length') && (
        <>
          <div className="mb-6 text-xl text-main font-semibold">
            {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Detail.courses')}
          </div>
          <Row gutter={[0, 32]}>
            {_.map(ctx.get('@item.program_template_courses', []), (item, index) => {
              return (
                <Col span={24} key={index}>
                  <DIV>
                    {ctx.debug(() => {
                      ctx.set('lesson_item', _.get(item, 'course_template', {}));
                    })}
                    <ProgramTemplateLessonItemX />
                  </DIV>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </DIV>
  );
};

export default displayName(View8);
