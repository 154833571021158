import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import _ from 'lodash';
import Button from '@uz/unitz-components-web/Button';
import { Row, Col } from 'antd';
import Link from '@uz/unitz-components-web/Link';
import CourseTemplateDescription from '@uz/unitz-tool-components/CourseTemplateDescription';
import ProgramTemplateLessons from '@uz/unitz-tool-components/ProgramTemplateLessons';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <DIV className="isLoading">
          <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
            <LoadingScreen />
          </div>
        </DIV>
        <DIV className="notLoading">
          <div className="p-4 h-full">
            <div className="p-4 bg-white500 rounded-lg shadow-xl">
              <div className="mb-4 text-main uppercase font-semibold text-2xl">
                {!!ctx.get('@item.name') ? ctx.get('@item.name') : ''}
              </div>
              {!!ctx.get('@item.description') && (
                <CourseTemplateDescription
                  data={ctx.get('@item.description')}
                  title={ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Detail.about_title')}
                />
              )}
              {!!ctx.get('@item.program_template_courses') && (
                <div className="my-8">
                  <ProgramTemplateLessons />
                </div>
              )}
              <Row justify="end">
                <Col span={12}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Link to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplates')}>
                        <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                          <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.back')}</span>
                        </Button>
                      </Link>
                    </Col>
                    <Col span={12}>
                      <Link to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplateEdit', { id: ctx.get('getTemplateId') })}>
                        <Button type="primary" className="w-full">
                          <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.edit')}</span>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </DIV>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
